/** @jsx jsx */
import { jsx, css } from "@emotion/react"
import React from "react"
import styled from "@emotion/styled"

// material-ui
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import Stack from "@mui/material/Stack"

import image1 from "../../assets/images/s01.png"
import image2 from "../../assets/images/s02.png"
import image3 from "../../assets/images/s03.png"
import image4 from "../../assets/images/s04.png"
import image5 from "../../assets/images/s05.png"
import image6 from "../../assets/images/s06.png"

// styles
import * as styles from "../../assets/styles/home.module.css"

const BoxCenter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

// ==============================|| Home Content Section FIVE ||============================== //

const ContentSection5 = () => {
  return (
    <Box className={styles.product}>
      <div className={styles.title}>
        <Typography variant="h3">建站流程</Typography>
      </div>
      <Stack
        sx={{ width: "100%", mb: 8 }}
        direction="row"
        justifyContent="space-around"
        alignItems="center"
      >
        <BoxCenter>
          <img src={image1} />
          <Typography variant="h6" sx={{ py: 2 }}>
            沟通并报价
          </Typography>
          <Typography variant="body2">提需求给予报价和周期</Typography>
        </BoxCenter>
        <BoxCenter>
          <img src={image2} />
          <Typography variant="h6" sx={{ py: 2 }}>
            签订协议
          </Typography>
          <Typography variant="body2">签建站协议，付款预支付</Typography>
        </BoxCenter>
        <BoxCenter>
          <img src={image3} />
          <Typography variant="h6" sx={{ py: 2 }}>
            网站设计
          </Typography>
          <Typography variant="body2">首页版面、颜色设计</Typography>
        </BoxCenter>
        <BoxCenter>
          <img src={image4} />
          <Typography variant="h6" sx={{ py: 2 }}>
            程序开发
          </Typography>
          <Typography variant="body2">系统设计、程序开发，测试运行</Typography>
        </BoxCenter>
        <BoxCenter>
          <img src={image5} />
          <Typography variant="h6" sx={{ py: 2 }}>
            上线运行
          </Typography>
          <Typography variant="body2">
            系统验收，付尾款，上线交付运行
          </Typography>
        </BoxCenter>
        <BoxCenter>
          <img src={image6} />
          <Typography variant="h6" sx={{ py: 2 }}>
            售后维护
          </Typography>
          <Typography variant="body2">系统免费终身维护</Typography>
        </BoxCenter>
      </Stack>
    </Box>
  )
}

export default ContentSection5
