import React from "react"

// material-ui
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"

// third-party
import Carousel from "react-slick"

// data
import { settings, partners } from "../../data/partner.source.js"

// styles
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import * as styles from "../../assets/styles/home.module.css"

// ==============================|| Home Content Section SEVEN ||============================== //

const ContentSection7 = () => {
  return (
    <Box sx={{ pt: "50px", pb: "100px" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "20px",
          marginBottom: "80px",
          minHeight: "20px",
        }}
      >
        <Typography variant="h3">合作服务商</Typography>
      </Box>
      {/* <Box
        sx={{
          color: "#3C4858",
          fontWeight: "700",
          fontFamily: `"Roboto Slab", "Times New Roman", serif`,
          marginTop: "80px",
          marginBottom: "30px",
          minHeight: "20px",
          textDecoration: "none",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <h1>合作服务商</h1>
      </Box> */}
      <Carousel
        {...settings}
        style={{
          margin: 0,
          padding: 0,
          border: 0,
          backgroundColor: "rgb(248,248,255)",
        }}
      >
        {partners.map(partner => {
          return (
            <img
              src={partner.logo}
              alt={partner.name}
              width="100%"
              height="100%"
              key={partner.id}
            />
          )
        })}
      </Carousel>
    </Box>
  )
}

export default ContentSection7
