import React from "react"
import * as image from "./rc.images.js"

export const settings = {
  centerMode: true,
  infinite: true,
  centerPadding: "60px",
  draggable: false,
  arrows: false,
  pauseOnHover: false,
  autoplay: true,
  slidesToShow: 5,
  speed: 3000,
  autoplaySpeed: 3000,
  rows: 3,
  slidesPerRow: 1,
  cssEase: "linear",
}

export const partners = [
  { id: "01", name: "阿里邮箱", logo: image.con3PartnerLG01 },
  { id: "02", name: "阿里巴巴", logo: image.con3PartnerLG02 },
  { id: "03", name: "猪八戒", logo: image.con3PartnerLG03 },
  { id: "04", name: "360搜索", logo: image.con3PartnerLG04 },
  { id: "05", name: "腾讯云", logo: image.con3PartnerLG05 },
  { id: "06", name: "百度智能云", logo: image.con3PartnerLG06 },
  { id: "07", name: "华为云", logo: image.con3PartnerLG07 },
  { id: "08", name: "腾讯网", logo: image.con3PartnerLG08 },
  { id: "09", name: "百度推广", logo: image.con3PartnerLG09 },
  { id: "10", name: "阿里云", logo: image.con3PartnerLG10 },
  { id: "11", name: "腾讯企业邮箱", logo: image.con3PartnerLG11 },
  { id: "12", name: "七牛云", logo: image.con3PartnerLG12 },
  { id: "13", name: "支付宝", logo: image.con3PartnerLG13 },
  { id: "14", name: "财付通", logo: image.con3PartnerLG14 },
  { id: "15", name: "钉钉", logo: image.con3PartnerLG15 },
  { id: "16", name: "京东智联云", logo: image.con3PartnerLG16 },
  { id: "17", name: "网易企业邮箱", logo: image.con3PartnerLG17 },
  { id: "18", name: "公众号", logo: image.con3PartnerLG18 },
]
