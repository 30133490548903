import React from "react"

// material-ui
import Grid from "@mui/material/Unstable_Grid2"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"

// styles
import * as styles from "../../assets/styles/home.module.css"

// ==============================|| Home Content Section SIX ||============================== //

const ContentSection6 = () => {
  return (
    <Box className={styles.product}>
      <div className={styles.title}>
        <Typography variant="h3">我们承诺</Typography>
      </div>
      <Grid container spacing={5}>
        <Grid xs={4}>
          <Box sx={{ border: "1px solid #C0C0C0", p: 3 }}>
            <Typography variant="h5" sx={{ pb: 2 }}>
              无隐性收费
            </Typography>
            <Typography variant="body2">
              重合同，守承诺，网站建设期间无隐性收费
            </Typography>
          </Box>
        </Grid>
        <Grid xs={4}>
          <Box sx={{ border: "1px solid #C0C0C0", p: 3 }}>
            <Typography variant="h5" sx={{ pb: 2 }}>
              交付源代码
            </Typography>
            <Typography variant="body2">交付网码，UI设计文件</Typography>
          </Box>
        </Grid>
        <Grid xs={4}>
          <Box sx={{ border: "1px solid #C0C0C0", p: 3 }}>
            <Typography variant="h5" sx={{ pb: 2 }}>
              项目不外包
            </Typography>
            <Typography variant="body2">
              项目不外包第三方公司，自主开发更优保障
            </Typography>
          </Box>
        </Grid>
        <Grid xs={4}>
          <Box sx={{ border: "1px solid #C0C0C0", p: 3 }}>
            <Typography variant="h5" sx={{ pb: 2 }}>
              签订正规合同
            </Typography>
            <Typography variant="body2">
              签订正规网站建设合同，开具发票，诚信合作
            </Typography>
          </Box>
        </Grid>
        <Grid xs={4}>
          <Box sx={{ border: "1px solid #C0C0C0", p: 3 }}>
            <Typography variant="h5" sx={{ pb: 2 }}>
              自主研发，拒绝侵权
            </Typography>
            <Typography variant="body2">
              自主研发，拒绝套用，保障权益
            </Typography>
          </Box>
        </Grid>
        <Grid xs={4}>
          <Box sx={{ border: "1px solid #C0C0C0", p: 3 }}>
            <Typography variant="h5" sx={{ pb: 2 }}>
              终身网站维护
            </Typography>
            <Typography variant="body2">网站维护，7x24小时在线</Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default ContentSection6
