import React from "react"

// gatsby ssr
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

// material-ui
import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"

// project imports
import ImageRotateX1 from "../../components/Animation/ImageRotateX1"

// styles
import * as styles from "../../assets/styles/home.module.css"

// import { homecase } from "../../data/homecase.source.js"

// ==============================|| Home Content Section THREE ||============================== //

const ContentSection3 = () => {
  const data = useStaticQuery(graphql`
    query {
      allCaseSourceJson {
        nodes {
          id
          casename
          caselink
          casetype
          casedesc
          caseimage {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                aspectRatio: 1.5
                placeholder: BLURRED
              )
            }
          }
        }
      }
    }
  `)
  console.log(data)
  const caselist = data.allCaseSourceJson.nodes

  return (
    <React.Fragment>
      <Box className={styles.product}>
        <div className={styles.title}>
          <Typography variant="h3">案例赏析</Typography>
        </div>
        <Grid container spacing={5}>
          {caselist.map(item => {
            const image = getImage(item?.caseimage)
            return (
              <Grid item xs={12} sm={6} md={4} key={item.id}>
                <ImageRotateX1
                  title={item.casename}
                  desc={item.casedesc}
                  link={item.caselink}
                >
                  <GatsbyImage image={image} alt="Case Image" />
                </ImageRotateX1>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    color: "rgb(255,250,250)",
                    bgcolor: "rgba(146, 115, 173, 0.9)",
                    px: 3,
                    py: 1,
                    borderRadius: "0 0 30% 30%",
                  }}
                >
                  <Typography variant="subtitle2">{item.casename}</Typography>
                  <Typography variant="body2">{item.casetype}</Typography>
                </Box>
              </Grid>
            )
          })}
        </Grid>
        <Button
          variant="contained"
          sx={{ my: 5, bgcolor: "rgba(116, 60, 127,0.7)" }}
        >
          查 看 更 多
        </Button>
      </Box>
    </React.Fragment>
  )
}

export default ContentSection3
