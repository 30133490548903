import React from "react"
import { StaticImage } from "gatsby-plugin-image"

// material-ui
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"

// styles
import * as styles from "../../assets/styles/home.module.css"

// ==============================|| Home Content Section ONE ||============================== //

const ContentSection1 = () => {
  return (
    <Box>
      <Box
        // sx={{
        //   position: "absolute",
        //   left: 0,
        //   top: 0,
        //   zIndex: -1,
        // }}
      >
        <StaticImage
          src="../../assets/images/banner01.jpg"
          placeholder="blurred"
          layout="constrained"
          imgStyle={{ width: "100%", height: "100vh" }}
          alt="background"
        />
      </Box>
      <Box className={styles.bannerText}>
        <Typography variant="h1" align="center" gutterBottom key={1}>
          <Box className={styles.primary}>一站式建站解决方案</Box>
        </Typography>
        <Typography variant="h1" align="center" gutterBottom key={2}>
          <Box className={styles.secondary}>建站+空间+域名+备案</Box>
        </Typography>
        {/* <MyImage image={backgroundImage2} alt="" key={3} /> */}
      </Box>
    </Box>
  )
}

export default ContentSection1
