import React from "react"
import styled from "@emotion/styled"

// material-ui
import Box from "@mui/material/Box"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"

// styles
import * as styles from "../../assets/styles/home.module.css"

const BoxCenter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

// ==============================|| Home Content Section FOUR ||============================== //

const ContentSection4 = () => {
  return (
    <Box className={styles.product}>
      {/* <div className={styles.title}>
          <Typography variant="h3">我们优势</Typography>
        </div> */}
      <Stack
        sx={{ width: "100%" }}
        direction="row"
        justifyContent="space-around"
        alignItems="center"
      >
        <BoxCenter>
          <Typography variant="h3" sx={{ py: 2 }}>
            10+
          </Typography>
          <Typography variant="h5">核心成员行业经验10年以上</Typography>
        </BoxCenter>
        <BoxCenter>
          <Typography variant="h3" sx={{ py: 2 }}>
            100%
          </Typography>
          <Typography variant="h5">100%站内深度优化</Typography>
        </BoxCenter>
        <BoxCenter>
          <Typography variant="h3" sx={{ py: 2 }}>
            360
          </Typography>
          <Typography variant="h5">全行业覆盖</Typography>
        </BoxCenter>
        <BoxCenter>
          <Typography variant="h3" sx={{ py: 2 }}>
            100%
          </Typography>
          <Typography variant="h5">项目交付率100%</Typography>
        </BoxCenter>
      </Stack>
    </Box>
  )
}

export default ContentSection4
