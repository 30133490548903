import React from "react"

// gatsby ssr
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

// material-ui
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"

// styles
import * as styles from "../../assets/styles/home.module.css"

// ==============================|| Home Content Section TWO ||============================== //

const ContentSection2 = props => {
  const data = useStaticQuery(graphql`
    query ProductData {
      allProductJson {
        nodes {
          title
          width
          height
          id
          url {
            childImageSharp {
              gatsbyImageData(
                height: 150
                width: 150
                placeholder: BLURRED
                layout: CONSTRAINED
              )
            }
          }
        }
      }
    }
  `)

  const products = data.allProductJson.nodes

  return (   
      <Box className={styles.product}>
        <Box className={styles.title}>
          <Typography variant="h3">产品服务</Typography>
        </Box>
        <Box className={styles.imgGrid}>
          {products.map((item, i) => {
            return (
              <Box key={item.id}>
                <GatsbyImage image={getImage(item?.url)} alt="" />
                <Box sx={{ my: 2, textAlign: "center" }}>{item.title}</Box>
              </Box>
            )
          })}
        </Box>
      </Box> 
  )
}

export default ContentSection2
