import React from "react"

// project imports
import Container from "../components/Container"
import ContentSection1 from "../screens/home/ContentSection1"
import ContentSection2 from "../screens/home/ContentSection2"
import ContentSection3 from "../screens/home/ContentSection3"
import ContentSection4 from "../screens/home/ContentSection4"
import ContentSection5 from "../screens/home/ContentSection5"
import ContentSection6 from "../screens/home/ContentSection6"
import ContentSection7 from "../screens/home/ContentSection7"

export default function HomePage() {
  return (
    <div>
      <ContentSection1 />
      <Container>
        <ContentSection2 />
      </Container>
      <Container bg="rgb(248,248,255)">
        <ContentSection3 />
      </Container>
      <Container>
        <ContentSection4 />
      </Container>
      <Container bg="rgb(248,248,255)">
        <ContentSection5 />
      </Container>
      <Container>
        <ContentSection6 />
      </Container>
      <Container bg="rgb(248,248,255)">
        <ContentSection7 />
      </Container>
    </div>
  )
}
